<template>
  <v-container>
    <RepMaxDialog
      :exercise="exercise"
      v-if="loaded"
      :show="show1RM"
      @repmax_done="closeRepmax"
      @repmax_cancel="show1RM = false"
    />
    <v-card flat class="mt-0 transparent">
      <BaseScreenHeader
        title="Exercise Stats"
        width="90%"
        screenInfo="STR-002"
        :showBack="true"
        :showNav="true"
      />

      <v-card flat tile class="charcoal paper--text mx-0">
        <v-sheet class="charcoal paper--text">
          <v-card-text :class="textSize + ' pl-2 pb-0'">
            <span
              class="link"
              title="View Exercise"
              @click="$router.push('/exercises/' + exerciseid)"
            >
              {{ exercise.name }}
              <BaseLinkIcon :large="!isPhone" />
            </span>
          </v-card-text>
          <v-card-actions
            v-if="loaded && statistics"
            :class="textSizeXSmall + ' silver--text py-0'"
          >
            {{ statistics.sets }} sets, {{ statistics.reps }} total reps<br />
            Average of
            {{ twoDigits(statistics.reps / statistics.sets) }} reps per set.<br />
            Volume:
            {{ prettyNumber(statistics.volume_imperial) }} lbs or
            {{ prettyNumber(statistics.volume_metric) }} kg
          </v-card-actions>
        </v-sheet>
        <v-divider class="charcoal lighten-1 mt-2" />
        <v-sheet
          color="charcoal"
          class="mb-1 mx-0 "
          rounded
          elevation="0"
          v-if="lastLifts.length > 0"
        >
          <v-card-title :class="textSize + ' py-0 px-2 silver--text'">
            <span class="">
              Last Lifts
            </span>
            <v-spacer />
          </v-card-title>
          <template>
            <v-card-actions
              v-for="(ex, index) in lastLifts"
              :key="index"
              :class="textSizeXSmall + ' silver--text  ml-2 px-2 py-0'"
            >
              {{ ex.reps }} reps @ {{ ex.imperial }} lbs or {{ ex.metric }} kg,
              {{ ex.effort }}% effort.
            </v-card-actions>
          </template>
        </v-sheet>
        <v-divider class="charcoal lighten-1 mb-2" />
        <v-card-actions class="pt-0 px-0  ">
          <v-spacer />
          <BaseActionButton
            label="Repmax Log"
            title="Full Repmax Log"
            icon="mdi-notebook-multiple"
            color="silver"
            :large="!isPhone"
            text
            @clickedThis="$router.push('/repmax/log/' + exerciseid)"
          />
        </v-card-actions>
        <v-sheet class="transparent pa-2" rounded :style="charcoalTileMenu">
          <v-sheet class="mt-1 transparent">
            <v-card-text :class="textSize + '   paper--text rounded pa-0'">
              Current 1RM
            </v-card-text>
            <v-card-text class="silver--text caption pa-0 mt-n1">
              <span v-if="trend.current > 0">
                Last tested {{ fromNow(trend.date_tested) }}, on
                {{ prettyDate(detail.created_local) }}
              </span>
              <span v-else class="pt-0">
                You did not enter any data for this lift.
              </span>
            </v-card-text>
            <v-sheet class="charcoalTileMenu transparent py-2 pl-2" rounded>
              <v-card-text
                v-if="trend.current > 0"
                :class="textSize + ' progressActive--text mt-2 pa-0'"
              >
                {{ trend.current }} lbs / {{ trend.current_metric }} kg
                <v-spacer />
                <span class="silver--text"> {{ trend.bwratio }} SELFS </span>
              </v-card-text>
              <v-card-actions class="pa-0 silver--text">
                <v-spacer />
                <span class="mr-1">Trending: </span>
                <template
                  v-if="trend.uptrend == 'Yes' && trend.long_uptrend == 'No'"
                >
                  <v-card-actions class="pa-0">
                    UP
                    <v-icon
                      title="Trending up on last three lifts"
                      x-large
                      class="green--text pl-2"
                    >
                      mdi-arrow-top-right-thick
                    </v-icon>
                  </v-card-actions>
                </template>
                <template
                  v-if="
                    trend.downtrend == 'Yes' && trend.long_downtrend == 'No'
                  "
                >
                  <v-card-actions class="pa-0">
                    DOWN
                    <v-icon
                      title="Trending down on last three lifts"
                      x-large
                      class="warning--text"
                    >
                      mdi-arrow-bottom-right-thick
                    </v-icon>
                  </v-card-actions>
                </template>
                <template v-if="trend.long_uptrend == 'Yes'">
                  <v-card-actions class="pa-0">
                    WAY UP
                    <v-icon
                      title="Trending up on last five lifts"
                      v-if="trend.long_uptrend == 'Yes'"
                      x-large
                      class="pinkAccent--text pl-2"
                    >
                      mdi-chevron-triple-up
                    </v-icon>
                  </v-card-actions>
                </template>
                <template v-if="trend.long_downtrend == 'Yes'">
                  <v-card-actions class="pa-0">
                    WAY DOWN
                    <v-icon
                      title="Trending down on last five lifts"
                      v-if="trend.long_downtrend == 'Yes'"
                      x-large
                      class="error--text pl-2"
                    >
                      mdi-chevron-triple-down
                    </v-icon>
                  </v-card-actions>
                </template>
                <template v-if="trend.plateux == 'Yes'">
                  <v-card-actions class="pa-0">
                    <v-spacer />
                    PLATEAU
                    <v-icon
                      title="Plateux"
                      v-if="trend.plateux == 'Yes'"
                      x-large
                      class="primary--text pl-2"
                    >
                      mdi-trendent-dc
                    </v-icon>
                  </v-card-actions>
                </template>
                <template
                  v-if="
                    trend.plateux == 'No' &&
                      trend.uptrend == 'No' &&
                      trend.long_uptrend == 'No' &&
                      trend.downtrend == 'No' &&
                      trend.long_downtrend == 'No'
                  "
                >
                  <v-card-actions class="pa-0">
                    UNCERTAIN
                    <v-icon
                      title="Uncertain"
                      v-if="
                        trend.plateux == 'No' &&
                          trend.uptrend == 'No' &&
                          trend.long_uptrend == 'No' &&
                          trend.downtrend == 'No' &&
                          trend.long_downtrend == 'No'
                      "
                      x-large
                      class="silver--text pl-2"
                    >
                      mdi-chart-timeline-variant
                    </v-icon>
                  </v-card-actions>
                </template>
              </v-card-actions>
            </v-sheet>
            <v-row class=" px-2">
              <v-col cols="12" class="pa-2" v-if="trend.current > 0">
                <v-simple-table flat class="transparent silver--text">
                  <thead>
                    <tr>
                      <th
                        colspan="2"
                        :class="textSize + ' text-right success--text'"
                      >
                        Weight (lbs/kg/selfs)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Maximum
                      </td>
                      <td class="text-right">
                        {{ detail.max_imperial }} / {{ detail.max_metric }} /
                        {{ detail.max_bwratio }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Minimum
                      </td>
                      <td class="text-right">
                        {{ detail.min_imperial }} / {{ detail.min_metric }} /
                        {{ detail.min_bwratio }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Average
                      </td>
                      <td class="text-right">
                        {{ detail.avg_imperial }} / {{ detail.avg_metric }} /
                        {{ detail.avg_bwratio }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Difference
                      </td>
                      <td class="text-right">
                        {{ detail.diff_imperial }} / {{ detail.diff_metric }} /
                        {{ detail.diff_bwratio }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-card-actions class="pl-0 ">
              <BaseActionButton
                v-if="trend.current > 0"
                label="Delete Last 1RM"
                title="Delete Last One Rep Max"
                icon="mdi-trash-can-outline"
                color="silver"
                :large="!isPhone"
                plain
                @clickedThis="deleteLast"
              />
              <v-spacer />
              <BaseActionButton
                label="1RM"
                title="Add One Rep Max"
                icon="mdi-plus-circle-outline"
                class="paper--text"
                :large="!isPhone"
                plain
                :style="charcoalTileMenu"
                @clickedThis="show1RM = true"
              />
            </v-card-actions>
          </v-sheet>
        </v-sheet>
        <v-card-actions class="pb-0 mt-2">
          <v-spacer />
          <v-checkbox
            dark
            v-model="metric"
            color="accent"
            label="Metric?"
            class="mb-n4"
          />
        </v-card-actions>
        <v-row dense>
          <v-col cols="12" md="6" class="mb-5 ">
            <StrengthChart
              v-if="trend.current > 0"
              :mode="mode"
              :thousands="false"
              :what="what"
              :selection="groupby"
              :key="switchnum"
              :exerciseid="exerciseid"
              @group_by="saveGroupBy"
              :metric="metric"
            />
            <VolumeChart
              v-else
              mode="byexercise"
              :what="what"
              :selection="groupby"
              :key="switchnum"
              @group_by="saveGroupBy"
              :metric="metric"
            />
          </v-col>

          <v-col cols="12" md="6" class="mb-10" v-if="trend.current > 0">
            <VolumeChart
              mode="byexercise"
              :what="what"
              :selection="groupby"
              :key="switchnum"
              @group_by="saveGroupBy"
              :metric="metric"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const StrengthChart = () =>
  import('@/components/charts/lifting/StrengthChart.vue')
const VolumeChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/lifting/VolumeChart.vue'
  )
const RepMaxDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/RepMaxDialog.vue')
export default {
  components: {
    StrengthChart,
    VolumeChart,
    RepMaxDialog
  },
  mixins: [util],
  name: 'MyStrength',
  data: () => ({
    exercise: {},
    exerciseid: '',
    statistics: {},
    basic: {},
    detail: {},
    trend: {},
    current: [],
    filterItem: {},
    what: { label: 'Exercise', id: null },
    basicMode: 'weight',
    type: 'imperial',
    mode: 'overall',
    groupby: null,
    loaded: false,
    switchnum: 0,
    feet: 0,
    inches: 0,
    metric: false,
    show1RM: false,
    showLastLifts: false,
    lastLifts: [],

    maxVolume: ''
  }),
  beforeMount() {
    this.exerciseid = this.$route.params.exerciseid
    this.loadBasic()
    this.loadDetail()
    this.loadExercise()
    this.loadStatistics()
    this.loadLastLifts()
  },
  mounted() {
    this.scrollToTop()
  },
  computed: {
    ...appConfig,
    ...builder,
    inBW() {
      return (
        Math.floor(((this.maxVolume * 1000) / this.basic.weight) * 100) / 100
      )
    }
  },
  methods: {
    closeRepmax() {
      this.show1RM = false
      this.loadExercise()
      window.scrollTo(0, 0)
    },
    saveGroupBy(value) {
      this.groupby = value
    },
    setMaxVolume(value) {
      this.maxVolume = value
    },
    loadLastLifts() {
      return axios
        .post(this.baseURL + '/workouts/lastlifts', {
          exerciseid: this.exerciseid,
          workoutinstanceid: 'all'
        })
        .then(response => {
          if (response.status == 200) {
            this.lastLifts = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBasic() {
      return axios
        .get(this.baseURL + '/stats/basic/' + this.type, {})
        .then(response => {
          if (response.status == 200) {
            this.basic = response.data.data
            this.feet = Math.floor(this.basic.height / 12)
            this.inches = this.basic.height % 12
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadDetail() {
      return axios
        .get(this.baseURL + '/stats/strength/detail/' + this.exerciseid)
        .then(response => {
          if (response.status == 200) {
            this.detail = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadStatistics() {
      return axios
        .get(this.baseURL + '/stats/exercise/' + this.exerciseid)
        .then(response => {
          if (response.status == 200) {
            this.statistics = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadExercise() {
      return axios
        .get(this.baseURL + '/exercises/view/' + this.exerciseid)
        .then(response => {
          if (response.status == 200) {
            this.exercise = response.data.data
            this.what.id = this.exercise.id
            this.what.label = this.exercise.name
            this.loadTrend()
            this.switchnum++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteLast() {
      return axios
        .delete(this.baseURL + '/exercise/repmax/' + this.exerciseid)
        .then(response => {
          if (response.status == 200) {
            this.loadExercise()
            this.switchnum++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadTrend() {
      return axios
        .get(this.baseURL + '/stats/lifting/trending/' + this.exerciseid, {})
        .then(response => {
          if (response.status == 200) {
            this.trend = response.data.data
            this.loaded = true
            this.switchnum++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
