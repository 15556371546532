<template>
  <v-container>
    <BaseScreenHeader
      title="Strength Standards"
      width="90%"
      screenInfo="STR-003"
      :showBack="true"
    />

    <v-card-text :class="textSizeXSmall + ' silver--text pb-0 px-2'">
      Use the tool below to find out where you stand as far as your strength
      level goes. We will calculate your
      <span class="progressActive--text">strength number</span> (overall and for
      each one of the fundamental strength exercises), and we will also show you
      what it takes to progress to the next level.
    </v-card-text>
    <v-card-actions class="py-0">
      <v-switch dark v-model="metric" :label="'Metric?'" @click="reset()" />
      <v-spacer />
      <v-radio-group dark row v-model="mySex" @change="switchGender">
        <v-radio label="Male" value="Male" />
        <v-radio label="Female" value="Female" />
      </v-radio-group>
    </v-card-actions>
    <v-card-text :class="textSizeXSmall + ' silver--text px-2 pt-0 pb-1'">
      Please enter your weight.
    </v-card-text>
    <v-card-text class="py-0 pl-2">
      <v-row dense>
        <v-col cols="5" md="3">
          <v-text-field
            dark
            :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
            filled
            color="success"
            type="number"
            label="Lbs"
            prepend-inner-icon="mdi-weight"
            v-model="imperialWeight"
            @input="calculateMetric"
            @focus="$event.target.select()"
          />
        </v-col>
        OR
        <v-col cols="5" md="3">
          <v-text-field
            dark
            :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
            filled
            color="success"
            prepend-inner-icon="mdi-weight"
            type="number"
            label="Kg"
            v-model="metricWeight"
            @input="calculateImperial"
            @focus="$event.target.select()"
          />
        </v-col>
        <v-spacer />
      </v-row>
    </v-card-text>
    <v-sheet class="transparent pa-0">
      <v-card-actions :class="textSize + ' paper--text py-0 pl-2'">
        Strength #
        <v-spacer />
        <span
          v-if="strengthNumber > 0"
          :class="textSizeLarge + ' success--text'"
        >
          {{ twoDigits(strengthNumber) }}%
        </span>
      </v-card-actions>
    </v-sheet>
    <v-card-text class="caption silver--text pt-0 px-2">
      Your strength number is calculated by averaging your strength relative to
      the optimal level for the major lifts below (back squat, bench press,
      deadlift and shoulder press).
    </v-card-text>
    <v-card
      v-for="(st, index) in my"
      :key="index"
      elevation="0"
      class="mb-2 pa-2 transparent charcoal--text charcoalTile"
    >
      <v-card-actions :class="textSizeSmall + ' charcoal--text px-0 py-0'">
        {{ st.name }}
        <v-spacer />
        <template v-if="st.oneRM > 0">
          <span class="accent--text pr-2">1RM:</span>{{ st.oneRM }}
          {{ unit(metric) }}
        </template>
      </v-card-actions>
      <v-card-text class="caption silver--text pt-0 px-0">
        Let's calculate your 1RM (One Rep Maximum) for
        <v-spacer />
        <span :class="textSize + ' paper--text'">{{ st.name }}</span>
      </v-card-text>
      <v-card-text class="py-0 px-0">
        <v-sheet color="transparent px-2">
          <v-row dense>
            <v-spacer />
            <v-col cols="8" md="3">
              <v-text-field
                dark
                color="success"
                class="enlarged-input-small"
                :label="'Weight Lifted (' + unit(metric) + ')'"
                ref="weight"
                v-model="st.weight"
                type="number"
                prepend-icon="mdi-weight"
                @input="calculateStandards()"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="4" md="3">
              <v-text-field
                dark
                color="success"
                class="enlarged-input-small"
                label="Reps"
                ref="reps"
                v-model="st.reps"
                type="number"
                prepend-icon="mdi-counter"
                @input="calculateStandards(st)"
              />
            </v-col>
          </v-row>
        </v-sheet>
        <template v-if="st.mylevel != null">
          <v-sheet color="silver lighten-3 primary--text pb-3">
            <v-card-actions :class="textSize + ' accent--text px-4 pt-4 pb-0'">
              {{ st.exercise_name }}
              <v-spacer />
            </v-card-actions>
            <v-card-actions class="py-0 px-4 text-uppercase font-weight-bold">
              <span :class="textSize + ' ' + strengthColor(st.mylevel, true)">
                {{ st.mylevel }}
              </span>
              <v-spacer />
              <span
                title="Your strength number for this lift relative to optimal"
                :class="textSize + ' ' + strengthColor(st.mylevel, true)"
              >
                {{ st.strength_coeficient }}%
              </span>
            </v-card-actions>
          </v-sheet>
          <v-list :class="textSizeXSmall">
            <v-list-item>
              <v-list-item-content class="silver--text">
                <v-list-item-title class="accent--text">
                  <span :class="textSizeXSmall + ' smallLabel'">
                    You:
                  </span>
                  <span :class="textSizeXSmall">
                    {{ twoDigits(st.bwratio) }} : {{ st.lifted }} lbs /
                    {{ st.liftedM }} kg
                  </span>
                </v-list-item-title>
                <v-list-item-title class="primary--text">
                  <span :class="textSizeXSmall + ' smallLabel'">
                    Level Up:
                  </span>
                  <span :class="textSizeXSmall">
                    {{ st.next }} ++ {{ st.nextI }}lbs / {{ st.nextM }} kg
                  </span>
                </v-list-item-title>
                <v-divider class="py-2" />
                <v-list-item-title
                  :class="st.mylevel == 'Beginner' ? 'accent--text' : ''"
                >
                  <span :class="textSizeXSmall + ' smallLabel'"
                    >Beginner:
                  </span>
                  <span :class="textSizeXSmall"
                    >&lt; {{ st.decent }} : {{ st.decentWeight }} lbs or
                    {{ st.decentWeightM }} kg</span
                  >
                </v-list-item-title>
                <v-list-item-title
                  :class="st.mylevel == 'Decent' ? 'accent--text' : ''"
                >
                  <span :class="textSizeXSmall + ' smallLabel'">Decent: </span>
                  <span :class="textSizeXSmall"
                    >{{ st.decent }} : {{ st.decentWeight }} lbs or
                    {{ st.decentWeightM }} kg</span
                  >
                </v-list-item-title>
                <v-list-item-title
                  :class="st.mylevel == 'Good' ? 'accent--text' : ''"
                >
                  <span :class="textSizeXSmall + ' smallLabel'">Good: </span>
                  <span :class="textSizeXSmall"
                    >{{ st.good }} : {{ st.goodWeight }} lbs or
                    {{ st.goodWeightM }} kg
                  </span>
                </v-list-item-title>
                <v-list-item-title
                  :class="st.mylevel == 'Optimal' ? 'accent--text' : ''"
                >
                  <span :class="textSizeXSmall + ' smallLabel'">Optimal: </span>
                  <span :class="textSizeXSmall"
                    >{{ st.optimal }} : {{ st.optimalWeight }} lbs or
                    {{ st.optimalWeightM }} kg</span
                  >
                </v-list-item-title>
                <v-list-item-title
                  :class="st.mylevel == 'Advanced' ? 'accent--text' : ''"
                >
                  <span :class="textSizeXSmall + ' smallLabel'"
                    >Advanced:
                  </span>
                  <span :class="textSizeXSmall"
                    >{{ st.advanced }} : {{ st.advancedWeight }} lbs or
                    {{ st.advancedWeightM }} kg</span
                  >
                </v-list-item-title>
                <v-list-item-title
                  :class="st.mylevel == 'Elite' ? 'accent--text' : ''"
                >
                  <span :class="textSizeXSmall + ' smallLabel'">Elite: </span>
                  <span :class="textSizeXSmall"
                    >{{ st.elite }} : {{ st.eliteWeight }} lbs or
                    {{ st.eliteWeightM }} kg</span
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  name: 'mystrength',
  components: {},
  data: () => ({
    search: '',
    tab: null,
    current: [],
    trending: [],
    merged: [],
    standards: [],
    loadedStandards: {},
    my: [],
    m: [],
    f: [],
    imperialWeight: 0,
    metricWeight: 0,
    strengthNumber: 0,
    loaded: false,
    switchnum: 0,
    show1RM: false,
    type: 'Weightlifting',
    metric: false,
    mySex: 'Male'
  }),
  beforeMount() {
    this.loadStandards()
  },
  validations: {},
  computed: {
    ...appConfig
  },
  methods: {
    switchGender() {
      this.reset()
      if (this.mySex == 'Male') {
        this.my = this.m
      } else this.my = this.f
    },
    reset() {
      this.standards = JSON.parse(JSON.stringify(this.loadedStandards))
      this.genderSplit()
      this.calculateStandards()
    },
    calculateImperial() {
      this.imperialWeight = this.twoDigits(
        this.metricWeight * (1 / this.factor)
      )
    },
    calculateMetric() {
      this.metricWeight = this.twoDigits(this.imperialWeight * this.factor)
    },
    recalculateRM(el) {
      let rm = this.calculateRM(el.reps, el.weight, this.metric)

      if (this.metric) {
        el.bwratio = rm.oneRMMetric / this.metricWeight
        el.oneRM = rm.oneRMMetric
      } else {
        el.bwratio = rm.oneRMImperial / this.imperialWeight
        el.oneRM = rm.oneRMImperial
      }
    },
    calculateStandards() {
      let iWeight = this.imperialWeight
      let mWeight = this.metricWeight
      let td = this.twoDigits

      this.standards.forEach(el => {
        this.recalculateRM(el)
        el.lifted = this.twoDigits(parseFloat(el.bwratio * iWeight))
        el.liftedM = this.twoDigits(parseFloat(el.bwratio * mWeight))

        el.decentWeight = this.twoDigits(parseFloat(el.decent * iWeight))
        el.goodWeight = this.twoDigits(parseFloat(el.good * iWeight))
        el.optimalWeight = this.twoDigits(parseFloat(el.optimal * iWeight))
        el.advancedWeight = this.twoDigits(parseFloat(el.advanced * iWeight))
        el.eliteWeight = this.twoDigits(parseFloat(el.elite * iWeight))

        el.decentWeightM = this.twoDigits(parseFloat(el.decent * mWeight))
        el.goodWeightM = this.twoDigits(parseFloat(el.good * mWeight))
        el.optimalWeightM = this.twoDigits(parseFloat(el.optimal * mWeight))
        el.advancedWeightM = this.twoDigits(parseFloat(el.advanced * mWeight))
        el.eliteWeightM = this.twoDigits(parseFloat(el.elite * mWeight))

        el.strength_coeficient = td((el.bwratio / el.optimal) * 100)

        if (el.bwratio < el.decent) {
          el.mylevel = 'Beginner'
          el.next = 'Decent'
          el.nextI = td(el.decentWeight - el.lifted)
          el.nextM = td(el.decentWeightM - el.liftedM)
        }
        if (el.bwratio >= el.decent && el.bwratio < el.good) {
          el.mylevel = 'Decent'
          el.next = 'Good'
          el.nextI = td(el.goodWeight - el.lifted)
          el.nextM = td(el.goodWeightM - el.liftedM)
        }
        if (el.bwratio >= el.good && el.bwratio < el.optimal) {
          el.mylevel = 'Good'
          el.next = 'Optimal'
          el.nextI = td(el.optimalWeight - el.lifted)
          el.nextM = td(el.optimalWeightM - el.liftedM)
        }
        if (el.bwratio >= el.optimal && el.bwratio < el.advanced) {
          el.mylevel = 'Optimal'
          el.next = 'Advanced'
          el.nextI = td(el.advancedWeight - el.lifted)
          el.nextM = td(el.advancedWeightM - el.liftedM)
        }
        if (el.bwratio >= el.advanced && el.bwratio < el.elite) {
          el.mylevel = 'Advanced'
          el.next = 'Elite'
          el.nextI = td(el.eliteWeight - el.lifted)
          el.nextM = td(el.eliteWeightM - el.liftedM)
        }
        if (el.bwratio >= el.elite) {
          el.mylevel = 'Elite'
          el.next = 'Elite'
          el.nextI = 0
          el.nextM = 0
        }
      })
      this.genderSplit()
    },
    genderSplit() {
      this.m = {}
      this.f = {}
      this.my = {}
      this.m = this.standards.filter(function(item) {
        return item.gender == 'Male'
      })
      this.f = this.standards.filter(function(item) {
        return item.gender == 'Female'
      })

      if (this.mySex == 'Male') this.my = this.m
      else this.my = this.f

      let withCoeficient = this.my.splice()
      withCoeficient = this.my.filter(el => el.strength_coeficient >= 0)

      this.strengthNumber =
        withCoeficient.reduce(
          (total, next) => total + next.strength_coeficient,
          0
        ) / withCoeficient.length
    },
    loadStandards() {
      return axios
        .get(this.baseURL + '/stats/strength/standards/' + this.type, {})
        .then(response => {
          if (response.status == 200) {
            this.standards = response.data.data
            this.loadedStandards = JSON.parse(JSON.stringify(this.standards))
            this.genderSplit()
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
