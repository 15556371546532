<template>
  <v-container>
    <BaseScreenHeader
      title="RepMax Log"
      screenInfo="STR-004"
      :showBack="true"
      :showNav="true"
    />

    <BaseFreeTier v-if="loaded && loadedList.length > 5" />
    <v-card-text class="pa-1 pb-0">
      <BaseSearch
        dark
        :items="loadedList"
        :label="loadedList.length + ' Repmaxes'"
        @search_results="setResults"
        what="exercise_name"
      />
    </v-card-text>
    <v-sheet class="charcoal">
      <v-timeline align-top :dense="isPhone">
        <v-timeline-item
          color="progressActive"
          fill-dot
          icon="mdi-circle-small"
          v-for="item in repmaxList"
          :key="item.id"
          :class="isPhone ? 'ml-n6' : ''"
        >
          <template v-slot:icon>
            <v-icon color="charcoal">
              mdi-trophy-award
            </v-icon>
          </template>
          <template v-slot:opposite>
            <span class="caption silver--text">
              {{ item.created }}
            </span>
          </template>
          <v-card
            :style="charcoalTileMenu"
            class="charcoal pa-2 ml-n2 rounded-lg link"
            rounded
            flat
            @click="selectItem(item)"
          >
            <v-card-actions class="py-0 pl-0">
              <span title="When" class="caption silver--text">
                {{ item.created }}
              </span>
              <v-spacer />
            </v-card-actions>
            <v-card-text :class="textSizeXSmall + ' px-0 pb-0'">
              <span title="Category" :class="textSizeSmall + ' paper--text'">
                {{ item.exercise_name }}
              </span>
              <v-spacer />
            </v-card-text>

            <v-card-actions :class="textSizeSmall + ' px-0 py-0 success--text'">
              {{ item.imperial }} lbs / {{ item.metric }} kg /
              {{ item.bwratio }} selfs
            </v-card-actions>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-sheet>
    <BaseLoading :loaded="loaded" />

    <v-dialog
      v-model="showRowInfo"
      :fullscreen="isPhone"
      scrollable
      width="850"
      overlay-opacity=".95"
    >
      <v-card class="charcoal d-flex flex-column noscroll">
        <BaseCloseDialogHeader
          heading="Manage Repmax For"
          @cancel="showRowInfo = false"
        />
        <v-card-text class="pb-10 px-2">
          <v-card-text :class="textSize + ' silver--text pa-0'">
            {{ selectedRepmax.exercise_name }}
          </v-card-text>
          <v-card-title class="silver--text caption px-0 pt-0">
            Entered on: {{ selectedRepmax.created }}
          </v-card-title>
          <v-card-actions :class="textSizeSmall + ' pa-0 paper--text'">
            {{ selectedRepmax.imperial }}lbs / {{ selectedRepmax.metric }} kg /
            {{ selectedRepmax.bwratio }} selfs
          </v-card-actions>
          <v-divider class="pb-4" />
          <v-spacer />
        </v-card-text>
        <v-spacer />
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-2 pt-2'">
          Review the information above. Click the
          <span class="progressActive--text">Delete</span> button to remove this
          entry from the log.
        </v-card-subtitle>
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            dark
            class="charcoal paper--text"
            :large="!isPhone"
            label="Delete"
            icon="mdi-trash-can-outline"
            @clickedThis="deleteItem"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

import debounce from 'lodash/debounce'

export default {
  name: 'RepMaxLog',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    },
    exerciseid: {
      type: String
    }
  },
  data() {
    return {
      loaded: false,
      selectedRepmax: {},
      metric: false,
      showRowInfo: false,
      activity: '',
      search: '',
      repmaxList: [],
      loadedList: [],
      debouncedScroll: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100,
      bottom: false,
      refresh: false
    }
  },
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    ...appConfig
  },
  methods: {
    setResults(value) {
      this.repmaxList = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    saved() {
      this.loadPage()
      this.showRepmaxDialog = false
    },
    cancel() {
      this.showAddDialog = false
    },
    selectItem(value) {
      this.showRowInfo = true
      this.selectedRepmax = value
    },
    deleteItem() {
      return axios
        .delete(this.baseURL + '/stats/strength/log/' + this.selectedRepmax.id)
        .then(response => {
          if (response.status == 200) {
            this.selectedRepmax = {}
            this.showRowInfo = false
            this.currentPage = 0
            this.refresh = true
            this.loadPage()
            this.toast('Repmax deleted succesfully.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPage() {
      this.loaded = false
      return axios
        .get(
          this.baseURL +
            '/stats/strength/log/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize +
            '/' +
            this.exerciseid,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            if (this.refresh) this.repmaxList = response.data.data
            else this.repmaxList = this.repmaxList.concat(response.data.data)

            this.loadedList = this.repmaxList.slice()
            this.loaded = true
            this.refresh = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
