<template>
  <v-container>
    <RepMaxDialog
      :exercise="selectedExercise"
      v-if="loaded"
      :show="show1RM"
      @repmax_done="closeRepmax"
      @repmax_cancel="show1RM = false"
    />

    <BaseScreenHeader
      title="My Strength"
      width="90%"
      screenInfo="STR-001"
      :showBack="true"
      :showNav="true"
    />

    <v-card-actions class="pa-0 my-2">
      <v-spacer />
      <BaseLinkButton
        v-if="merged.length > 0"
        label="Repmax Log"
        title="Full Repmax Log"
        icon="mdi-open-in-new"
        text
        color="paper"
        class="mr-n2"
        @clickedThis="$router.push('/repmax/log/all')"
      />
    </v-card-actions>
    <BaseFreeTier v-if="loaded && current.length > 5" />
    <v-tabs
      active-class="progressActive charcoal--text "
      class="paper--text rounded"
      v-if="loaded"
      v-model="tab"
      center-active
      centered
      grow
      dark
      icons-and-text
      show-arrows
      background-color="charcoal"
      v-touch:swipe.left="tabLeft"
      v-touch:swipe.right="tabRight"
    >
      <v-tabs-slider color="progressActive rounded" />
      <v-tab href="#tested" class="silverLink">
        Tested
        <v-icon>mdi-checkbox-multiple-marked-circle-outline</v-icon>
      </v-tab>
      <v-tab href="#standards" class="silverLink">
        Standards
        <v-icon>mdi-monitor-star</v-icon>
      </v-tab>
      <v-tab href="#untested" class="silverLink">
        Untested
        <v-icon>mdi-progress-check</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="charcoal">
      <v-tab-item key="tested" value="tested" reverse-transition>
        <v-sheet rounded class="transparent py-2 mt-4" :style="charcoalTile">
          <v-card-actions :class="textSize + ' pa-0'">
            <v-select
              height="70"
              dark
              solo
              chips
              v-model="sortBy"
              :items="strengthSortByList"
              label="Sort By?"
              persistent-hint
              item-text="text"
              item-value="value"
              prepend-inner-icon="mdi-sort"
              class="success--text rounded"
              background-color="charcoal lighten-2"
              menu-icon="mdi-arrow-bottom-right"
              @change="changeSort"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 120,
                transition: 'fab-transition'
              }"
            >
              <template
                v-slot:selection="{ item, index, selected, disabled, remove }"
              >
                <v-chip
                  class="silver lighten-3 pa-4 text-h6"
                  :key="index"
                  :input-value="item.value"
                  :disabled="disabled"
                >
                  <span class="black--text"> {{ item.text }}</span>
                </v-chip>
              </template>
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="transparent">
                  <v-list-item-content class="paper lighten-2">
                    <v-list-item-title class="text-h6 black--text">{{
                      item.text
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-card-actions>
          <v-card-actions class="pa-0">
            <BaseSearch
              dark
              :items="merged"
              light
              :label="merged.length + ' Measured Lifts'"
              :searchIn="['name']"
              @search_results="searchLifts"
            />
          </v-card-actions>
        </v-sheet>
        <template v-if="merged.length > 0 && loaded">
          <v-card flat class="charcoal mt-4" tile>
            <v-row dense>
              <v-col
                cols="12"
                md="6"
                v-for="curr in merged"
                :key="curr.id"
                :elevation="0"
                class="my-2"
              >
                <v-sheet
                  color=" rounded-lg transparent paper--text charcoalTileMenu"
                  elevation="1"
                >
                  <v-card-title
                    :class="textSize + ' px-0 pb-0'"
                    :title="curr.name"
                  >
                    <span
                      class="link"
                      title="View Exercise History"
                      @click="
                        $router.push('/strengthdetail/' + curr.exerciseid)
                      "
                    >
                      <BaseLinkIcon />
                      {{ curr.name }}
                    </span>
                    <v-spacer />
                  </v-card-title>
                  <v-card-actions class="pb-1 pt-0 mt-n2">
                    <span class="silver--text">One Rep Max</span>
                    <v-spacer />
                    <span class="caption silver--text mr-1">
                      TREND
                    </span>
                    <v-card-actions class="pa-0 ma-0">
                      <v-spacer />
                      <v-icon
                        title="Trending up on last three lifts"
                        v-if="
                          curr.uptrend == 'Yes' && curr.long_uptrend == 'No'
                        "
                        x-large
                        class="success--text"
                      >
                        mdi-arrow-top-right-thick
                      </v-icon>
                      <v-icon
                        title="Trending down on last three lifts"
                        v-if="
                          curr.downtrend == 'Yes' && curr.long_downtrend == 'No'
                        "
                        x-large
                        class="warning--text"
                      >
                        mdi-arrow-bottom-right-thick
                      </v-icon>
                      <v-icon
                        title="Trending up on last five lifts"
                        v-if="curr.long_uptrend == 'Yes'"
                        x-large
                        class="pinkAccent--text"
                      >
                        mdi-chevron-triple-up
                      </v-icon>
                      <v-icon
                        title="Trending down on last five lifts"
                        v-if="curr.long_downtrend == 'Yes'"
                        x-large
                        class="error--text"
                      >
                        mdi-chevron-triple-down
                      </v-icon>
                      <v-icon
                        title="Plateux"
                        v-if="curr.plateux == 'Yes'"
                        x-large
                        class="paper--text"
                      >
                        mdi-current-dc
                      </v-icon>
                      <v-icon
                        title="Uncertain"
                        v-if="
                          curr.plateux == 'No' &&
                            curr.uptrend == 'No' &&
                            curr.long_uptrend == 'No' &&
                            curr.downtrend == 'No' &&
                            curr.long_downtrend == 'No'
                        "
                        x-large
                        class="orange--text"
                      >
                        mdi-chart-timeline-variant
                      </v-icon>
                    </v-card-actions>
                  </v-card-actions>
                  <v-card-title
                    :class="textSize + ' pa-0 mx-2 progressActive--text'"
                  >
                    {{ oneDigit(curr.imperial) }} lbs /
                    {{ oneDigit(curr.metric) }} kg
                  </v-card-title>
                  <v-card-actions
                    :class="textSizeSmall + ' py-0 mt-n1 purpleAccent--text'"
                  >
                    BW {{ curr.bwratio }} SELFS
                  </v-card-actions>
                  <v-spacer />

                  <v-card-actions class="pl-2 caption silver--text">
                    <span>
                      Tested
                      {{ fromNow(curr.created_local) + ', &nbsp;on&nbsp;' }}
                    </span>
                    {{ prettyDate(curr.created_local) }}
                  </v-card-actions>
                  <v-card-actions class="px-0">
                    <v-spacer />
                    <BaseActionButton
                      text
                      :large="!isPhone"
                      label="Add New 1RM"
                      class="paper--text"
                      icon="mdi-plus-circle-outline"
                      @clickedThis="collectOneRepMax(curr.exerciseid)"
                    />
                  </v-card-actions>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <template v-else>
          <v-card-text v-if="current.length == 0" :class="textSizeXSmall+' silver--text pa-0'">
            This section will populate after you enter 1RM for any weight
            lifting exercises.
          </v-card-text>
          <v-card-text :class="textSizeSmall + ' paper--text pa-0 mt-2'">
            We found no 1RM (one rep max) for any of your lifts.
            <v-spacer />
            Please go to the <a class="text-h6" @click="tabUntested()">UNTESTED</a> tab to add one.
          </v-card-text>
        </template>
      </v-tab-item>
      <v-tab-item key="untested" value="untested" reverse-transition>
        <v-card flat class="charcoal" tile>
          <v-card-title class="pa-0 mt-2">
            <v-text-field
              dark
              clearable
              filled
              color="success"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="'Search ' + untested.length + ' untested exercises'"
              single-line
              hide-details
              background-color="charcoal lighten-2"
              solo
            />
          </v-card-title>
          <v-card-text :class="textSizeXSmall + ' silver--text pa-0 mt-1'">
            Click on an exercise below to add its first 1RM.
          </v-card-text>
          <v-card-text class="pa-0 mt-2">
            <v-data-table
              mobile-breakpoint="0"
              :headers="headers"
              :items="untested"
              :items-per-page="itemsPerPage"
              :page="page"
              :search="search"
              hide-default-footer
              hide-default-header
              class="transparent pa-0 ma-0 charcoalTile"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-card-actions
                  @click="collectOneRepMax(item.id)"
                  :class="textSizeSmall + ' link pa-0 paper--text'"
                >
                  <v-icon color="silver" class="ml-n3">
                    mdi-chevron-right
                  </v-icon>
                  <span>{{ item.name }}</span>
                  <v-spacer />
                </v-card-actions>
              </template>
              <template v-slot:footer>
                <v-card-actions
                  :style="charcoalTile"
                  class="ml-0 pt-2 pb-4 px-4 caption"
                >
                  <span class="silver--text">Per page</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn class="caption" text color="silver" v-on="on">
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(number, index) in itemsPerPageArray"
                        :key="index"
                        @click="updateItemsPerPage(number)"
                      >
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer />
                  <span class="pa-0 silver--text">
                    Page {{ page }} of {{ numberOfPages }}
                  </span>
                  <v-btn fab text x-small class="mr-0" @click="formerPage">
                    <v-icon color="silver">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text x-small class="ml-0" @click="nextPage">
                    <v-icon color="silver">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        key="standards"
        value="standards"
        class="charcoal"
        reverse-transition
      >
        <v-card-actions :class="textSize + ' px-1 pb-0 mt-2 silver--text'">
          <v-divider class="charcoal lighten-1" />
          <span class="mx-2 paper--text">Strength Standards</span>
          <v-divider class="charcoal lighten-1" />
        </v-card-actions>
        <template v-if="standards.length == 8">
          <v-row no-gutters v-if="loaded" class="">
            <v-col cols="12" md="6">
              <v-card class="rounded-lg charcoal charcoalTileMenu">
                <v-card-title :class="textSize + ' paper--text pb-0 pr-2'">
                  Powerlifting Total
                  <v-spacer />
                  <v-icon
                    large
                    class="silver--text"
                    @click="showPowerLifting = !showPowerLifting"
                  >
                    mdi-information
                  </v-icon>
                </v-card-title>
                <v-card-text
                  :class="textSizeSmall + ' purpleAccent--text pb-0'"
                >
                  <span :class="textSizeLarge + '  progressActive--text'">
                    {{ Math.ceil(powerlifting.imperial) }} lbs /
                    {{ Math.ceil(powerlifting.metric) }} kg</span
                  >
                  <v-spacer class="pb-1" />
                  <span class="silver--text">Body Weight Total: </span>
                  {{ powerlifting.bw_sum }} SELFS
                  <v-spacer />
                  <span class="silver--text">Body Weight Average:</span>
                  {{ powerlifting.bw_avg }} SELFS
                </v-card-text>
                <v-card-actions class="py-0">
                  <v-spacer />
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-chart-multiple"
                    text
                    color="charcoal"
                    label="Visualize"
                    @clickedThis="$router.push('/stats/powerlifting')"
                  />
                </v-card-actions>
                <template v-if="showPowerLifting" :class="textSizeXSmall">
                  <v-card-text class="silver--text pt-0 px-2">
                    Powerlifting total adds up your best 1RM for back squat,
                    deadlift and bench press. Here's how this breaks down for
                    you.
                  </v-card-text>
                  <v-timeline dense align-top class="">
                    <v-timeline-item
                      flat
                      v-for="(exercise, index) in powerliftingExercises"
                      :key="index"
                      color="progressActive"
                      small
                      right
                      fill-dot
                      :class="isPhone ? 'ml-n8' : ''"
                    >
                      <v-card-title
                        class="link pa-0 mt-n2 paper--text"
                        @click="
                          $router.push('/exercises/' + exercise.exerciseid)
                        "
                      >
                        {{ exercise.exercise_name }}
                      </v-card-title>
                      <v-card-text
                        class="progressActive--text caption py-0 mt-n1 pl-0"
                      >
                        <span :class="textSize">
                          {{ exercise.imperial }} lbs / {{ exercise.metric }} kg
                        </span>
                        <v-spacer />
                        <span class="charcoal--text">
                          {{ exercise.bwratio }} SELFS
                        </span>
                        <v-spacer />
                      </v-card-text>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="rounded-lg charcoal charcoalTileMenu mt-2">
                <v-card-title :class="textSize + ' paper--text pb-0 pr-2'">
                  Crossfit Total (CFT)
                  <v-spacer />
                  <v-icon
                    large
                    class="silver--text"
                    @click="showCFT = !showCFT"
                  >
                    mdi-information
                  </v-icon>
                </v-card-title>
                <v-card-text
                  :class="textSizeSmall + ' purpleAccent--text pb-0'"
                >
                  <span :class="textSizeLarge + '  progressActive--text'">
                    {{ Math.ceil(cft.imperial) }} lbs /
                    {{ Math.ceil(cft.metric) }} kg</span
                  >
                  <v-spacer />
                  <span class="silver--text">Body Weight Total:</span>
                  {{ cft.bw_sum }} SELFS
                  <v-spacer />
                  <span class="silver--text">Body Weight Average:</span>
                  {{ cft.bw_avg }} SELFS
                </v-card-text>
                <v-card-actions class="py-0">
                  <v-spacer />
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-chart-multiple"
                    text
                    color="charcoal"
                    label="Visualize"
                    @clickedThis="$router.push('/stats/powerlifting')"
                  />
                </v-card-actions>
                <v-card-text
                  v-if="showCFT"
                  :class="textSizeXSmall + ' px-2 pt-0'"
                >
                  <v-card-text class="silver--text py-0 px-0">
                    CFT (Crossfit total) adds up your best 1RM for back squat,
                    deadlift and shoulder press. Here's how this breaks down for
                    you.
                  </v-card-text>
                  <v-timeline dense align-top class="">
                    <v-timeline-item
                      flat
                      v-for="(exercise, index) in cftExercises"
                      :key="index"
                      color="progressActive"
                      small
                      fill-dot
                      right
                      :class="isPhone ? 'ml-n8' : ''"
                    >
                      <v-card-title
                        class="link pa-0 mt-n1 paper--text"
                        @click="
                          $router.push('/exercises/' + exercise.exerciseid)
                        "
                      >
                        {{ exercise.exercise_name }}
                      </v-card-title>
                      <v-card-text
                        class="progressActive--text caption py-0 pl-0"
                      >
                        <span :class="textSize">
                          {{ exercise.imperial }} lbs / {{ exercise.metric }} kg
                        </span>
                        <v-spacer />
                        <span class="charcoal--text">
                          {{ exercise.bwratio }} SELFS
                        </span>
                        <v-spacer />
                      </v-card-text>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text> </v-card
            ></v-col>
          </v-row>

          <v-card-actions :class="textSize + ' paper--text pt-4 pb-0'">
            <v-divider />
            <span class="mx-2">RANK {{ strengthNumber.myrank }}</span>
            <v-divider />
          </v-card-actions>
          <v-sheet
            class="charcoal rounded-lg paper--text pa-2 charcoalTileMenu"
          >
            <v-card-actions class="py-0">
              <v-icon
                large
                class="silver--text"
                @click="showPowerStrengthNum = !showPowerStrengthNum"
              >
                mdi-information
              </v-icon>
              <v-spacer />
              <span>
                STRENGTH NUMBER
              </span>

              <v-spacer />
              <v-icon
                large
                class="silver--text"
                @click="showPowerStrengthNum = !showPowerStrengthNum"
              >
                mdi-information
              </v-icon>
            </v-card-actions>
            <v-card-actions class="py-0">
              <v-spacer />
              <span :class="textSizeLarge + ' progressActive--text'">
                {{ oneDigit(strengthNumber.strength_number) }}%
              </span>
              <v-spacer />
            </v-card-actions>
            <v-card-text
              v-if="showPowerStrengthNum"
              class="silver--text pa-0"
              v-html="tooltips['strength#']"
            >
            </v-card-text>
          </v-sheet>
          <v-card-actions class="pb-0 mt-2 mb-n4">
            <v-spacer />
            <v-radio-group dark row v-model="mySex" @change="switchGender">
              <v-radio label="Male" value="Male" />
              <v-radio label="Female" value="Female" />
            </v-radio-group>
          </v-card-actions>
          <v-card
            v-for="(st, index) in my"
            :key="index"
            flat
            rounded
            class="charcoal mb-4 charcoalTile"

          >
                 <v-sheet class=" charcoal paper--text charcoalTileMenu">
                <v-card-actions
                  :class="textSize + ' paper--text px-0 pt-2 pb-0'"
                >
                  <span
                    class="link"
                    title="View Exercise History"
                    @click="$router.push('/strengthdetail/' + st.exercise)"
                  >
                    <BaseLinkIcon />
                    {{ st.exercise_name }}
                  </span>
                  <v-spacer />
                </v-card-actions>

                <v-card-actions
                  class=" text-uppercase font-weight-bold pa-2 pt-0 rounded-lg"
                >
                  <span
                    :class="textSize + ' ' + strengthColor(st.mylevel, true)"
                  >
                    {{ st.mylevel }}
                  </span>
                  <v-spacer />
                  <span
                    title="Your strength number for this lift relative to optimal"
                    :class="textSize + ' ' + strengthColor(st.mylevel, true)"
                  >
                    {{ oneDigit(st.strength_coeficient) }}%
                  </span>
                </v-card-actions>
              </v-sheet>

              <v-list :style="charcoalTile" :class="textSizeXSmall + ' transparent '">
                <v-list-item class="px-2">
                  <v-list-item-content class="paper--text">
                    <v-list-item-title class="cyan--text">
                      <span :class="textSizeSmall + ' smallLabel'">
                        You:
                      </span>
                      <span :class="textSizeSmall">
                        {{ st.bwratio }} - {{ st.lifted }} lbs /
                        {{ st.liftedM }} kg
                      </span>
                    </v-list-item-title>
                    <v-list-item-title class="silver--text">
                      <span :class="textSizeSmall + ' smallLabel'">
                        Level Up:
                      </span>
                      <span :class="textSizeSmall">
                        {{ st.next }} ++ {{ st.nextI }}lbs / {{ st.nextM }} kg
                      </span>
                    </v-list-item-title>
                    <v-divider class="py-2" />
                    <v-list-item-title
                      :class="
                        st.mylevel == 'Beginner' ? 'progressActive--text' : ''
                      "
                    >
                      <span :class="textSizeXSmall + ' smallLabel'"
                        >Beginner:
                      </span>
                      <span :class="textSizeXSmall">
                        less than {{ st.decent }} {{ st.decentWeight }} lbs or
                        {{ st.decentWeightM }} kg</span
                      >
                    </v-list-item-title>
                    <v-list-item-title
                      :class="
                        st.mylevel == 'Decent' ? 'progressActive--text' : ''
                      "
                    >
                      <span :class="textSizeXSmall + ' smallLabel'"
                        >Decent:
                      </span>
                      <span :class="textSizeXSmall">
                        greater than {{ st.decent }} - {{ st.decentWeight }} lbs
                        or {{ st.decentWeightM }} kg</span
                      >
                    </v-list-item-title>
                    <v-list-item-title
                      :class="
                        st.mylevel == 'Good' ? 'progressActive--text' : ''
                      "
                    >
                      <span :class="textSizeXSmall + ' smallLabel'"
                        >Good:
                      </span>
                      <span :class="textSizeXSmall">
                        greater than {{ st.good }} - {{ st.goodWeight }} lbs or
                        {{ st.goodWeightM }} kg
                      </span>
                    </v-list-item-title>
                    <v-list-item-title
                      :class="
                        st.mylevel == 'Optimal' ? 'progressActive--text' : ''
                      "
                    >
                      <span :class="textSizeXSmall + ' smallLabel'"
                        >Optimal:
                      </span>
                      <span :class="textSizeXSmall">
                        greater than {{ st.optimal }} -
                        {{ st.optimalWeight }} lbs or
                        {{ st.optimalWeightM }} kg</span
                      >
                    </v-list-item-title>
                    <v-list-item-title
                      :class="
                        st.mylevel == 'Advanced' ? 'progressActive--text' : ''
                      "
                    >
                      <span :class="textSizeXSmall + ' smallLabel'">
                        Advanced:
                      </span>
                      <span :class="textSizeXSmall">
                        greater than {{ st.advanced }} -
                        {{ st.advancedWeight }} lbs or
                        {{ st.advancedWeightM }} kg</span
                      >
                    </v-list-item-title>
                    <v-list-item-title
                      :class="
                        st.mylevel == 'Elite' ? 'progressActive--text' : ''
                      "
                    >
                      <span :class="textSizeXSmall + ' smallLabel'">
                        Elite:
                      </span>
                      <span :class="textSizeXSmall">
                        greater than {{ st.elite }} - {{ st.eliteWeight }} lbs
                        or {{ st.eliteWeightM }} kg</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
      
          </v-card>
        </template>
        <template v-else>
          <v-card-text :class="textSizeSmall + ' pt-0 paper--text pb-4'">
            Strength standards data will be available after you enter your 1RM
            (One Rep Max) for four basic lifts below:
            <div class="mt-1">
              <v-icon color="progressActive" class="mr-1 ml-2 mt-n1">
                mdi-numeric-1-box
              </v-icon>
              <a
                href="/exercises/bDyTHQ3Ubk"
                title="Bench Press - Barbell"
                class="progressActive--text"
                >Bench Press</a
              >
            </div>
            <div>
              <v-icon color="progressActive" class="mr-1 ml-2 mt-n1">
                mdi-numeric-2-box
              </v-icon>
              <a
                href="/exercises/4ytJ7xgMKG"
                title="Deadlift"
                class="progressActive--text"
                >Deadlift</a
              >
            </div>
            <div>
              <v-icon color="progressActive" class="mr-1 ml-2 mt-n1">
                mdi-numeric-3-box
              </v-icon>
              <a
                href="/exercises/IAN9v$upDU"
                title="Back Squat"
                class="progressActive--text"
                >Back Squat</a
              >
            </div>
            <div>
              <v-icon color="progressActive" class="mr-1 ml-2 mt-n1">
                mdi-numeric-4-box
              </v-icon>
              <a
                href="/exercises/HC*4veJS6$"
                title="Shoulder Press (Strict Press)"
                class="progressActive--text"
                >Shoulder Press</a
              >
            </div>
          </v-card-text>
          <v-card-text :class="textSizeSmall + ' paper--text pa-0 mt-2'">
            Please go to the <a class="text-h6" @click="tabUntested()">UNTESTED</a> tab to add one.
          </v-card-text>
        </template>
      </v-tab-item>
    </v-tabs-items>

    <LoadingDialog
      :show="!loaded"
      title="LOADING MY STRENGTH..."
      color="accent"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const RepMaxDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/RepMaxDialog.vue')
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
import strengthSortByList from '@/json/strengthsortby.json'

import tooltips from '@/json/tooltips.json'
export default {
  components: { RepMaxDialog, LoadingDialog },
  mixins: [util],
  name: 'MyStrength',
  data: () => ({
    search: '',
    sortBy: 'date',
    tab: null,
    current: [],
    tooltips: tooltips,
    strengthSortByList: strengthSortByList,
    trending: [],
    merged: [],
    untested: [],
    standards: [],
    my: [],
    m: [],
    f: [],
    metric: {},
    imperial: {},
    strengthNumber: {},
    userStrengthNumbers: [],
    userStrengthExercises: [],
    powerliftingExercises: [],
    cftExercises: [],
    powerlifting: {},
    cft: {},
    loaded: false,
    showPowerLifting: false,
    showPowerStrengthNum: false,
    showCFT: false,
    switchnum: 0,
    show1RM: false,
    itemsPerPageArray: [20, 50, 100, 500],
    itemsPerPage: 100,
    page: 1,
    selectedExercise: {},
    mySex: 'Male',
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'name',
        class: 'accent--text'
      }
    ],
    tabs: [
      { index: '0', name: 'tested' },
      { index: '1', name: 'standards' },
      { index: '2', name: 'untested' }
    ]
  }),
  beforeMount() {
    this.loadBasicImperial()
    this.loadCurrent()
    this.loadStrengthNumber()
    this.loadUntested()
    if (this.sex != null) this.mySex = this.sex
  },
  mounted() {},
  computed: {
    itemsCount() {
      return this.untested.length
    },
    numberOfPages() {
      return Math.ceil(this.untested.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    changeSort() {
      this.loadCurrent()
    },
    tabUntested() {
      this.tab = 'untested'
    },
    tabLeft() {
      let currentTab = this.tabs.find(el => el.name == this.tab)
      let currentIndex = parseInt(currentTab.index)
      if (currentIndex < this.tabs.length - 1) {
        let nextTab = this.tabs.find(el => el.index == currentIndex + 1)
        this.tab = nextTab.name
      }
    },
    tabRight() {
      let currentTab = this.tabs.find(el => el.name == this.tab)
      let currentIndex = parseInt(currentTab.index)
      if (currentIndex > 0) {
        let nextTab = this.tabs.find(el => el.index == currentIndex - 1)
        this.tab = nextTab.name
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    switchGender() {
      if (this.mySex == 'Male') {
        this.my = this.m
      } else this.my = this.f
    },
    searchLifts(value) {
      this.merged = value
    },
    closeRepmax() {
      this.show1RM = false
      this.loadCurrent()
    },
    collectOneRepMax(value) {
      this.loadExercise(value)
      this.show1RM = true
    },
    loadCurrent() {
      return axios
        .get(this.baseURL + '/stats/strength/current/yes/' + this.sortBy, {})
        .then(response => {
          if (response.status == 200) {
            this.merged = []
            this.current = response.data.data
            this.loadTrends()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadTrends() {
      return axios
        .get(this.baseURL + '/stats/lifting/trending/all', {})
        .then(response => {
          if (response.status == 200) {
            this.trending = response.data.data
            if (this.trending.length > 0) {
              for (let i = 0; i < this.current.length; i++) {
                this.merged.push({
                  ...this.current[i],
                  ...this.trending.find(
                    itmInner =>
                      itmInner.exerciseid === this.current[i].exerciseid
                  )
                })
              }
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadUntested() {
      return axios
        .get(this.baseURL + '/stats/strength/untested', {})
        .then(response => {
          if (response.status == 200) {
            this.untested = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadExercise(exerciseid) {
      return axios
        .get(this.baseURL + '/exercises/view/' + exerciseid, {})
        .then(response => {
          if (response.status == 200) {
            this.selectedExercise = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadStandards() {
      return axios
        .get(this.baseURL + '/stats/strength/mystandards', {})
        .then(response => {
          if (response.status == 200) {
            this.standards = response.data.data
            if (this.standards.length > 0 && !this.isEmpty(this.imperial)) {
              let iWeight = this.imperial.weight
              let mWeight = this.metric.weight
              let td = this.twoDigits

              this.standards.forEach(el => {
                el.lifted = this.twoDigits(parseFloat(el.bwratio * iWeight))
                el.decentWeight = this.twoDigits(
                  parseFloat(el.decent * iWeight)
                )
                el.goodWeight = this.twoDigits(parseFloat(el.good * iWeight))
                el.optimalWeight = this.twoDigits(
                  parseFloat(el.optimal * iWeight)
                )
                el.advancedWeight = this.twoDigits(
                  parseFloat(el.advanced * iWeight)
                )
                el.eliteWeight = this.twoDigits(parseFloat(el.elite * iWeight))
                el.liftedM = this.twoDigits(parseFloat(el.bwratio * mWeight))
                el.decentWeightM = this.twoDigits(
                  parseFloat(el.decent * mWeight)
                )
                el.goodWeightM = this.twoDigits(parseFloat(el.good * mWeight))
                el.optimalWeightM = this.twoDigits(
                  parseFloat(el.optimal * mWeight)
                )
                el.advancedWeightM = this.twoDigits(
                  parseFloat(el.advanced * mWeight)
                )
                el.eliteWeightM = this.twoDigits(parseFloat(el.elite * mWeight))
                if (el.mylevel == 'Beginner') {
                  el.next = 'Decent'
                  el.nextI = td(el.decentWeight - el.lifted)
                  el.nextM = td(el.decentWeightM - el.liftedM)
                }
                if (el.mylevel == 'Decent') {
                  el.next = 'Good'
                  el.nextI = td(el.goodWeight - el.lifted)
                  el.nextM = td(el.goodWeightM - el.liftedM)
                }
                if (el.mylevel == 'Good') {
                  el.next = 'Optimal'
                  el.nextI = td(el.optimalWeight - el.lifted)
                  el.nextM = td(el.optimalWeightM - el.liftedM)
                }
                if (el.mylevel == 'Optimal') {
                  el.next = 'Advanced'
                  el.nextI = td(el.advancedWeight - el.lifted)
                  el.nextM = td(el.advancedWeightM - el.liftedM)
                }
                if (el.mylevel == 'Advanced') {
                  el.next = 'Elite'
                  el.nextI = td(el.eliteWeight - el.lifted)
                  el.nextM = td(el.eliteWeightM - el.liftedM)
                }
              })

              this.m = this.standards.filter(function(item) {
                return item.gender == 'Male'
              })
              this.f = this.standards.filter(function(item) {
                return item.gender == 'Female'
              })

              if (this.mySex == 'Male') this.my = this.m
              else this.my = this.f
            } // end if any standards
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBasicImperial() {
      return axios
        .get(this.baseURL + '/stats/basic/imperial', {})
        .then(response => {
          if (response.status == 200) {
            this.imperial = response.data.data
            this.loadBasicMetric()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBasicMetric() {
      return axios
        .get(this.baseURL + '/stats/basic/metric', {})
        .then(response => {
          if (response.status == 200) {
            this.metric = response.data.data
            this.loadStandards()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadStrengthNumber() {
      {
        return axios
          .get(this.baseURL + '/stats/strength/number', {})
          .then(response => {
            if (response.status == 200) {
              this.strengthNumber = response.data.data
              this.loadUserStrengthNumbers()
              this.loadUserStrengthExercises()
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadUserStrengthNumbers() {
      {
        return axios
          .get(this.baseURL + '/stats/strength/numbers', {})
          .then(response => {
            if (response.status == 200) {
              this.userStrengthNumbers = response.data.data
              this.powerlifting = this.userStrengthNumbers.filter(function(
                item
              ) {
                return item.type == 'Powerlifting'
              })[0]
              this.cft = this.userStrengthNumbers.filter(function(item) {
                return item.type == 'CFT'
              })[0]
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadUserStrengthExercises() {
      {
        return axios
          .get(this.baseURL + '/stats/strength/exercises', {})
          .then(response => {
            if (response.status == 200) {
              this.userStrengthExercises = response.data.data
              this.powerliftingExercises = this.userStrengthExercises.filter(
                function(item) {
                  return item.powerlifting == 'Yes'
                }
              )
              this.cftExercises = this.userStrengthExercises.filter(function(
                item
              ) {
                return item.cft == 'Yes'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
